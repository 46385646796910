import { TypographyStyleOptions } from '@material-ui/core/styles/createTypography'

interface FontStyle {
    [key: string]: TypographyStyleOptions
}

const fontStyles: FontStyle = {
    body1: {
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: 1.0,
    },
    body2: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '1.1rem',
    },
    subtitle1: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.75rem',
    },
    subtitle2: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.80rem',
        // lineHeight: 0.75,
    },
    button: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.9375rem',
        // lineHeight: 1.125,
        textTransform: 'uppercase',
    },
    FormTextSmall: {
        //h6
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.875rem',
        // lineHeight: 1,
    },
    SaveProgressTitle: {
        //h5
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '1.25rem',
        // lineHeight: 2.125,
    },
    Abschnittssubline: {
        //h4
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '1.375rem',
        // lineHeight: 1.625,
    },
    ProduktboxProduktTitel: {
        //h3
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '1.5rem',
        // lineHeight: 1.75,
    },
    UbersichtTitel: {
        //h2
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '1.875rem',
        // lineHeight: 2.125,
    },
    Abschnittsheadline: {
        //h1
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '2.875rem',
        // lineHeight: 3.375,
    },
}
export default fontStyles
