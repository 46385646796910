import { Box, Grid, Typography } from '@material-ui/core'
import { ProgressListProps } from 'components/ProgressList/ProgressList'
import { ProgressListItemProps } from 'components/ProgressListItem/ProgressListItem'
import CheckIcon from 'components/ProgressListSubItem/CheckIcon'
import { ProgressListItemState } from 'components/ProgressListSubItem/ProgressListSubItem'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'

const MobileProgressList = (props: ProgressListProps): JSX.Element => {
    const { steps } = props

    const { t } = useTranslation()

    return (
        <Grid item container className={'MobileProgressList'} spacing={1} wrap={'nowrap'}>
            {steps.map((item: ProgressListItemProps, index: number) => {
                switch (item.status) {
                    case ProgressListItemState.UNFINISHED:
                        return (
                            <Grid item xs={'auto'} key={index}>
                                <Box
                                    width={30}
                                    height={30}
                                    borderRadius={15}
                                    bgcolor={colors.lightGray}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                />
                            </Grid>
                        )
                    case ProgressListItemState.FINISHED:
                        return (
                            <Grid item xs={'auto'} key={index}>
                                <Box
                                    width={30}
                                    height={30}
                                    borderRadius={15}
                                    bgcolor={colors.mainAlternative}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <CheckIcon color={colors.white} width={'20px'} height={'16px'} />
                                </Box>
                            </Grid>
                        )
                    // ProgressListItemState.ACTIVE
                    default:
                        return (
                            <Grid item xs key={index}>
                                <Box
                                    padding={'2px 8px'}
                                    height={1}
                                    width={1}
                                    borderRadius={5}
                                    bgcolor={colors.lightGray}
                                    display={'flex'}
                                    alignItems={'center'}
                                    boxSizing={'border-box'}
                                    justifyContent={'space-between'}
                                >
                                    <Typography className={'MobileProgressListItem'}>
                                        {t('mobile_' + item.i18nKey)}
                                    </Typography>
                                    <Box height={10} width={10} bgcolor={colors.mainAlternative} borderRadius={5} />
                                </Box>
                            </Grid>
                        )
                }
            })}
        </Grid>
    )
}

export default MobileProgressList
