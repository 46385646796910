import { ContactDataState } from 'store/ContactData/ContactData.reducer'

export enum ContactDataAction {
    SET_TO_INIT_STATE = 'SET_CONTACT_DATA_TO_INIT_STATE',
    SET_PERSONAL_SALUTATION = 'SET_PERSONAL_SALUTATION',
    SET_PERSONAL_TITLE = 'SET_PERSONAL_TITLE',
    SET_PERSONAL_NAME = 'SET_PERSONAL_NAME',
    SET_PERSONAL_LAST_NAME = 'SET_PERSONAL_LAST_NAME',
    SET_PERSONAL_STREET = 'SET_PERSONAL_STREET',
    SET_PERSONAL_HOUSE_NUMBER = 'SET_PERSONAL_HOUSE_NUMBER',
    SET_PERSONAL_ZIP = 'SET_PERSONAL_ZIP',
    SET_PERSONAL_CITY = 'SET_PERSONAL_CITY',
    SET_PERSONAL_EMAIL = 'SET_PERSONAL_EMAIL',
    SET_PERSONAL_BIRTH_DATE = 'SET_PERSONAL_BIRTH_DATE',
    SET_PERSONAL_TELEPHONE = 'SET_PERSONAL_TELEPHONE',
    SET_PERSONAL_MOBILE_PHONE = 'SET_PERSONAL_MOBILE_PHONE',
    SET_PERSONAL_ORDER_COMMENT = 'SET_PERSONAL_ORDER_COMMENT',
    TOGGLE_DEVIATING_DELIVERY_ADDRESS = 'TOGGLE_DEVIATING_DELIVERY_ADDRESS',
    SET_DELIVERY_SALUTATION = 'SET_DELIVERY_SALUTATION',
    SET_DELIVERY_TITLE = 'SET_DELIVERY_TITLE',
    SET_DELIVERY_NAME = 'SET_DELIVERY_NAME',
    SET_DELIVERY_LAST_NAME = 'SET_DELIVERY_LAST_NAME',
    SET_DELIVERY_STREET = 'SET_DELIVERY_STREET',
    SET_DELIVERY_HOUSE_NUMBER = 'SET_DELIVERY_HOUSE_NUMBER',
    SET_DELIVERY_ZIP = 'SET_DELIVERY_ZIP',
    SET_DELIVERY_CITY = 'SET_DELIVERY_CITY',
    SET_DELIVERY_ADDITIONAL_ADDR_INFO = 'SET_DELIVERY_ADDITIONAL_ADDR_INFO',
    TOGGLE_DEVIATING_BILLING_ADDRESS = 'TOGGLE_DEVIATING_BILLING_ADDRESS',
    SET_BILLING_SALUTATION = 'SET_BILLING_SALUTATION',
    SET_BILLING_TITLE = 'SET_BILLING_TITLE',
    SET_BILLING_NAME = 'SET_BILLING_NAME',
    SET_BILLING_LAST_NAME = 'SET_BILLING_LAST_NAME',
    SET_BILLING_STREET = 'SET_BILLING_STREET',
    SET_BILLING_HOUSE_NUMBER = 'SET_BILLING_HOUSE_NUMBER',
    SET_BILLING_ZIP = 'SET_BILLING_ZIP',
    SET_BILLING_CITY = 'SET_BILLING_CITY',
    SET_BILLING_ADDITIONAL_ADDR_INFO = 'SET_BILLING_ADDITIONAL_ADDR_INFO',
    SET_CONTACT_DATA_STATE_PARTIAL = 'SET_CONTACT_DATA_STATE',
    SET_CONTACT_STATE_PARTIAL = 'SET_CONTACT_DATA_STATE_PARTIAL',
}

type ContactDataActions =
    | { type: ContactDataAction.SET_TO_INIT_STATE }
    | { payload: Partial<ContactDataState>; type: ContactDataAction.SET_CONTACT_STATE_PARTIAL }
    | { payload: string; type: ContactDataAction.SET_PERSONAL_SALUTATION }
    | { payload: string; type: ContactDataAction.SET_PERSONAL_TITLE }
    | { payload: string; type: ContactDataAction.SET_PERSONAL_NAME }
    | { payload: string; type: ContactDataAction.SET_PERSONAL_LAST_NAME }
    | { payload: string; type: ContactDataAction.SET_PERSONAL_EMAIL }
    | { payload: string; type: ContactDataAction.SET_PERSONAL_BIRTH_DATE }
    | { payload: string; type: ContactDataAction.SET_PERSONAL_TELEPHONE }
    | { payload: string; type: ContactDataAction.SET_PERSONAL_MOBILE_PHONE }
    | { payload: string; type: ContactDataAction.SET_PERSONAL_ORDER_COMMENT }
    | { type: ContactDataAction.TOGGLE_DEVIATING_DELIVERY_ADDRESS }
    | { payload: string; type: ContactDataAction.SET_DELIVERY_SALUTATION }
    | { payload: string; type: ContactDataAction.SET_DELIVERY_TITLE }
    | { payload: string; type: ContactDataAction.SET_DELIVERY_NAME }
    | { payload: string; type: ContactDataAction.SET_DELIVERY_LAST_NAME }
    | { payload: string; type: ContactDataAction.SET_DELIVERY_STREET }
    | { payload: string; type: ContactDataAction.SET_DELIVERY_HOUSE_NUMBER }
    | { payload: string; type: ContactDataAction.SET_DELIVERY_ZIP }
    | { payload: string; type: ContactDataAction.SET_DELIVERY_CITY }
    | { payload: string; type: ContactDataAction.SET_DELIVERY_ADDITIONAL_ADDR_INFO }
    | { type: ContactDataAction.TOGGLE_DEVIATING_BILLING_ADDRESS }
    | { payload: string; type: ContactDataAction.SET_BILLING_SALUTATION }
    | { payload: string; type: ContactDataAction.SET_BILLING_TITLE }
    | { payload: string; type: ContactDataAction.SET_BILLING_NAME }
    | { payload: string; type: ContactDataAction.SET_BILLING_LAST_NAME }
    | { payload: string; type: ContactDataAction.SET_BILLING_STREET }
    | { payload: string; type: ContactDataAction.SET_BILLING_HOUSE_NUMBER }
    | { payload: string; type: ContactDataAction.SET_BILLING_ZIP }
    | { payload: string; type: ContactDataAction.SET_BILLING_CITY }
    | { payload: string; type: ContactDataAction.SET_DELIVERY_ADDITIONAL_ADDR_INFO }
    | { payload: ContactDataState; type: ContactDataAction.SET_CONTACT_DATA_STATE_PARTIAL }

export default ContactDataActions
