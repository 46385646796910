import { AccountType, ProductType, SelectedProductType } from 'graphql/types'
import { TFunction } from 'i18next'
import { AppState } from 'store/store'
import { Customize } from './customize'

export interface ExcelData {
    products: string[][]
    addresses: string[][]
    telephone: string[][]
    other: string[][]
}

export const generateExcelData = (
    state: AppState,
    t: TFunction,
    B2B: boolean,
    customizeJsData: Customize | undefined,
): ExcelData => {
    const data: ExcelData = {
        products: [],
        addresses: [],
        telephone: [],
        other: [],
    }

    if (!customizeJsData) return data

    //Collect all the data which products the customer selected
    data.products.push(['Produkt', 'Internetoption', 'Telefonoption', 'Fernsehoption'])

    state.generalState.selectedProductCategories.forEach((category, i) => {
        const selectedCategory = state.generalState.availableProductCategories.find((apc) => apc.id === category.id)
        if (!selectedCategory) return

        const selectedProduct = selectedCategory.products.find((p) => p.id === category.selectedProduct?.id)
        if (!selectedProduct) return

        if (!data.products[i + 1]) {
            data.products.push(['', '', '', ''])
        }
        data.products[i + 1][0] = selectedProduct.title

        category.selectedProduct?.productTypes.forEach((productType) => {
            const selectedProcutType = selectedProduct.productTypes.find((pt) => pt.id === productType.id)
            if (!selectedProcutType) return

            const options = getSelectedOptions(selectedProcutType, productType)
            let columnIndex = 1

            if (selectedProcutType.identifier === 'telephone') {
                columnIndex = 2
            } else if (selectedProcutType.identifier === 'television') {
                columnIndex = 3
            }

            options.forEach((option, index) => {
                if (!data.products[index + 1]) {
                    data.products.push(['', '', '', ''])
                }
                data.products[index + 1][columnIndex] = option
            })
        })
    })

    //Collect all the data with addresses
    data.addresses.push([
        'Anschlussadresse',
        'Anrede',
        'Titel',
        'Vorname',
        'Nachname',
        'Straße',
        'Hausnummer',
        'PLZ',
        'Ort',
        'E-Mail',
        'Geburtsdatum',
        'Telefon',
        'Mobiltelefon',
    ])

    const cd = state.contactData
    const ac = state.availabilityCheck
    const bd = state.bankDetails
    const ps = state.portabilityState

    //put in the connection address
    data.addresses.push([
        'Bisherige Anschlussadresse',
        cd.personalSalutation,
        cd.personalTitle,
        cd.personalName,
        cd.personalLastName,
        ac.selectedStreet,
        ac.selectedHouseNumber,
        ac.zip,
        ac.selectedCity,
        cd.personalEmail,
        cd.personalBirthDate,
        cd.personalTelephone,
        cd.personalMobilePhone,
        cd.personalOrderComment,
    ])

    //put in the billing address
    if (cd.deviatingBillingAddress) {
        data.addresses.push([
            'Abweichende Rechnungsadresse',
            cd.billingSalutation,
            cd.billingTitle,
            cd.billingName,
            cd.billingLastName,
            cd.billingStreet,
            cd.billingHouseNumber,
            cd.billingZip,
            cd.billingCity,
        ])
    }

    //get the delivery address
    if (cd.deviatingDeliveryAddress) {
        data.addresses.push([
            'Abweichende Lieferadresse',
            cd.deliverySalutation,
            cd.deliveryTitle,
            cd.deliveryName,
            cd.deliveryLastName,
            cd.deliveryStreet,
            cd.deliveryHouseNumber,
            cd.deliveryZip,
            cd.deliveryCity,
        ])
    }

    //get the delivery address
    if (bd.differentAccountHolder) {
        data.addresses.push([
            'Abweichender Kontoinhaber',
            bd.accountHolderData.salutation,
            bd.accountHolderData.title,
            bd.accountHolderData.name,
            bd.accountHolderData.lastName,
            bd.accountHolderData.street,
            bd.accountHolderData.houseNumber,
            bd.accountHolderData.zip,
            bd.accountHolderData.city,
        ])
    }

    //get the telephone configuration
    data.telephone.push([
        'Rufnummer mitnehmen?',
        'Vorwahl	Rufnummer',
        'Bisheriger Festnetzanbieter',
        'Bisheriger Vertagsinhaber',
        'Kündigung Festnetzvertrag',
        'Eintrag ins Telefonbuch?',
        'Rufnummernsperren',
        'Einzelverbindungsnachweis',
    ])

    let portabilityPhoneNumber = 'n'
    let selectedProvider = ''
    let terminatedContract = 'n'
    let entryInTelephoneBook = 'n'
    let numbersActivate = ''
    let contractHolder = ''
    let numbers = 'n'

    if (customizeJsData.telephoneOptions.portabilityVisible) {
        const portabilityValue = state.generalState.configuration.get(
            customizeJsData.portabilityConfiguration.showPortability.identifier,
        )
        if (portabilityValue === customizeJsData.portabilityConfiguration.showPortability.wishValue) {
            portabilityPhoneNumber = 'j'
            selectedProvider = ps.selectedProvider
        }

        if (ps.selectedRadios.includes('terminatedContractNo')) {
            terminatedContract = ps.endOfContract
        }

        if (ps.selectedRadios.includes('contractHolderNo')) {
            ps.contractHolderOptions.forEach((ch) => {
                if (contractHolder.length > 0) contractHolder += ', '
                contractHolder += ch.firstName + ' ' + ch.lastName
            })
        } else {
            contractHolder = 'ich selbst'
        }

        numbers = ''
        ps.phoneOptions.forEach((portabilityTelephone) => {
            if (numbers.length > 0) numbers += ', '
            numbers += portabilityTelephone.areaCode + portabilityTelephone.number
        })
    }

    if (state.generalState.configuration.get('entryInPhoneBook') === 'yesEntryInPhoneBook') {
        entryInTelephoneBook = 'j'
    }

    if (state.generalState.configuration.get('numbersActivate')) {
        const numbers = state.generalState.configuration.get('numbersActivate') as string[]
        numbers.forEach((n) => {
            numbersActivate += t(n) + ', '
        })
    }

    data.telephone.push([
        portabilityPhoneNumber,
        numbers,
        selectedProvider,
        contractHolder,
        terminatedContract,
        entryInTelephoneBook,
        numbersActivate,
        t(state.generalState.configuration.get('proofOfIndividualConnection') as string),
    ])

    //get the bank and installation configuration
    data.other.push(['Rechnungsversand', 'Zahlmethode', 'Etage', 'Wohnung', 'Anschlussdose', 'Wunschtermin'])

    let invoiceSend = ''
    let accountType = ''
    let junctionBox = ''
    let desiredDate = ''

    if (state.generalState.configuration.get('invoiceSend')) {
        const invoiceSendValue = state.generalState.configuration.get('invoiceSend') as string
        invoiceSend = t(invoiceSendValue)
    }

    if (bd.accountType === AccountType.IBAN) {
        accountType = bd.iban
    } else {
        accountType = t('orderOverviewStrings.bankDetails.transfer')
    }

    if (state.generalState.customizeJsData) {
        if (state.generalState.customizeJsData.installationDetailsConfiguration.junctionBox) {
            junctionBox = t(
                'installationDetailsStrings.junctionBox.' + state.generalState.installationDetails.junctionBox,
            )
        }
    }

    if (state.generalState.earliestDatePossible || state.generalState.desiredDate === null) {
        desiredDate = t('orderOverviewStrings.desiredDate.earliestDatePossible')
    } else {
        desiredDate = state.generalState.desiredDate.toLocaleDateString()
    }

    data.other.push([
        invoiceSend,
        accountType,
        state.generalState.installationDetails.floorInformation,
        state.generalState.installationDetails.flatPosition,
        junctionBox,
        desiredDate,
    ])

    return data
}

const getSelectedOptions = (selectedProcutType: ProductType, productType: SelectedProductType): string[] => {
    const options: string[] = []
    productType.optionCategories.forEach((optionCategory) => {
        const selectedOptionCategory = selectedProcutType.category.find((c) => c.id === optionCategory.id)
        if (!selectedOptionCategory) return

        optionCategory.selectedOptions.forEach((option) => {
            const selectOption = selectedOptionCategory.options.find((o) => o.id === option)
            if (!selectOption) return
            options.push(selectOption.title)
        })
    })

    return options
}
