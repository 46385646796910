import { Box, ButtonBase, Grid, Typography } from '@material-ui/core'
import InputCounter from 'components/InputCounter/InputCounter'
import { OptionType } from 'store/GeneralState/GeneralState.reducer'
import TestIDs from '../../utils/TestIDs'
import colors from '../../utils/colors'
import OptionImage from '../OptionImage'
import PriceDescription, { PriceDescriptionProps } from '../PriceDescription/PriceDescription'

export interface OptionItemContent {
    id: string
    price?: PriceDescriptionProps
    selectedImage?: string
    subtitle?: string
    info?: string
    datasheet?: string
    title: string
    unselectedImage?: string
    multiSelectedMin?: number
    multiSelectedMax?: number
    multiSelectedEnabled?: boolean
    preconditionArticle?: string[]
}

export interface OptionItemProps {
    content: OptionItemContent
    type: OptionType
    selected: boolean
    multiSelectedCounter?: number
    increaseSelection?: (id: string, min: number, max: number) => void
    decreaseSelection?: (id: string, min: number, max: number) => void
    onSelect: (id: string) => void
    disabled?: boolean
}

const OptionItem = (props: OptionItemProps): JSX.Element => {
    const { type, content, selected, onSelect, decreaseSelection, increaseSelection, multiSelectedCounter, disabled } =
        props
    // const titleIsLong = (str: string, image1: string | undefined, image2: string | undefined): boolean => {
    //     if (image1 !== undefined && image2 !== undefined && str.length > 30) return true
    //     return false
    // }

    return (
        <Grid item xs={12} key={content.id + selected} className={selected ? 'Selected' : ''}>
            <ButtonBase
                disabled={disabled ?? false}
                {...TestIDs.GET_OPTION_ITEM(content.id)}
                onClick={(): void => onSelect(content.id)}
                className={'OptionItemComponent'}
                style={{
                    backgroundColor: selected ? colors.color2 : colors.white,
                    border: `2px solid ${selected ? colors.color2 : colors.midGray}`,
                }}
            >
                <Grid container>
                    <Grid item xs={12} sm={'auto'}>
                        <Box
                            display={'flex'}
                            justifyContent={{ xs: 'left', sm: 'center' }}
                            alignItems={'center'}
                            pl={{ xs: 2, sm: 0 }}
                            width={{ xs: 1, sm: 76 }}
                            height={{ xs: 60, sm: 1 }}
                            bgcolor={selected ? colors.color2 : colors.lightGray}
                            boxSizing={'border-box'}
                        >
                            <Box
                                width={32}
                                height={32}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                borderRadius={type === OptionType.CHECKBOX ? '5px' : '16px'}
                                bgcolor={colors.white}
                            >
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        (type === OptionType.CHECKBOX
                                            ? '/customize/checkboxSelected.svg'
                                            : '/customize/radioSelected.svg')
                                    }
                                    alt={type === OptionType.CHECKBOX ? '✓' : '.'}
                                    style={{ opacity: selected ? 1 : 0, transition: '250ms all' }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid container item xs={12} sm>
                        <Grid container item xs={12} sm>
                            <Grid item xs={12} sm>
                                <Box
                                    height={{ xs: 'auto', sm: 1 }}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    width={1}
                                    pt={{ xs: 3, sm: 0 }}
                                    textAlign={{ xs: 'center', sm: 'left' }}
                                    pl={1}
                                    pr={1}
                                    boxSizing={'border-box'}
                                    pb={{ xs: 3, sm: 0 }}
                                    color={selected ? colors.white : undefined}
                                >
                                    <Grid item>
                                        <Typography
                                            variant={'h2'}
                                            // variant={
                                            //     titleIsLong(
                                            //         content.title,
                                            //         content.selectedImage,
                                            //         content.unselectedImage,
                                            //     )
                                            //         ? 'h3'
                                            //         : 'h2'
                                            // }
                                            dangerouslySetInnerHTML={{ __html: content.title }}
                                        />
                                    </Grid>
                                    {content.subtitle && (
                                        <Grid item>
                                            <Typography
                                                style={selected ? { color: colors.white } : undefined}
                                                variant={'subtitle1'}
                                                className={'OptionItemSubtitle'}
                                                dangerouslySetInnerHTML={{ __html: content.subtitle }}
                                            />
                                        </Grid>
                                    )}
                                </Box>
                            </Grid>
                            {content.multiSelectedEnabled && (
                                <Grid item xs={12} sm={'auto'}>
                                    <Box
                                        height={{ xs: 'auto', sm: 1 }}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        width={1}
                                        pb={!content.price ? { xs: 3, sm: 0 } : 0}
                                        pr={!content.price ? { xs: 0, sm: 2 } : 0}
                                        boxSizing={'border-box'}
                                    >
                                        <InputCounter
                                            min={content.multiSelectedMin}
                                            max={content.multiSelectedMax}
                                            count={multiSelectedCounter}
                                            id={content.id}
                                            decrease={decreaseSelection}
                                            increase={increaseSelection}
                                        />
                                    </Box>
                                </Grid>
                            )}
                            {content.selectedImage !== undefined && content.unselectedImage !== undefined && (
                                <Grid item xs={12} sm={'auto'}>
                                    <Box
                                        height={{ xs: 'auto', sm: 1 }}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        width={1}
                                        pb={!content.price ? { xs: 3, sm: 0 } : 0}
                                        pr={!content.price ? { xs: 0, sm: 2 } : 0}
                                        boxSizing={'border-box'}
                                    >
                                        <OptionImage
                                            selectedImage={content.selectedImage}
                                            unselectedImage={content.unselectedImage}
                                            selected={selected}
                                        />
                                    </Box>
                                </Grid>
                            )}
                            {content.price !== undefined && (
                                <Grid item xs={12} sm={4}>
                                    <Box
                                        height={{ xs: 'auto', sm: 1 }}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        width={1}
                                        pb={{ xs: 2, sm: 0 }}
                                        pl={1}
                                        pr={1}
                                        boxSizing={'border-box'}
                                        flexDirection={'column'}
                                        textAlign={{ xs: 'center', sm: 'right' }}
                                        className={'OptionPriceContainer'}
                                        pt={{ xs: 3, sm: 0 }}
                                    >
                                        <PriceDescription
                                            newPrice={content.price.newPrice}
                                            oldPrice={content.price.oldPrice}
                                            info={content.price.info}
                                            datasheet={content.datasheet}
                                            billMode={content.price.billMode}
                                            description={content.price.description}
                                            isBasket={false}
                                        />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </ButtonBase>
        </Grid>
    )
}

export default OptionItem
